__webpack_public_path__ = window.__webpack_public_path__;

import { createPico, sessionManager } from '@bendingspoons/pico-core';
import {
  indexedDBPersistence,
  localStorageID,
  syncSessionStorageID,
} from '@bendingspoons/pico-web';

import { browserName, isDesktop, isMobile, isTablet, osName, osVersion } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

const debug = window.__pico_debug__;
const disableClient = window.__pico_client_disabled__;
const disableSending = window.__pico_disable_sending__;
const bspId = window.__pico_bsp_id__ || "loomly_web";
const environment = window.__pico_environment__ || "staging";

let pico;
let pendingUserActions = [];

initializePico();

const extractUserData = ()=> {
  const element = document.getElementById("user-data-tag");
  return element ? JSON.parse(element.textContent) : {};
};

const extractUserId = ()=> extractUserData()?.id?.toString();

const dispatchPicoUserAction = (kind, info)=> {
  if (disableSending) {
    debug && console.log("Sending Pico User Action Event, but sending disabled: ", kind, info);
  } else {
    debug && console.log("Sending Pico User Action Event: ", kind, info);
    pico.trackUserAction({ kind, info });
  }
};

// Main invocation of user event tracking. If Pico isn't initialized yet, we queue up the event for auto-dispatching
// after initialization. Otherwise, dispatch right away.
//
window.trackPicoUserAction = (kind, info)=> {
  if (!pico) {
    debug && console.log("Pico not yet initialized, but tracked event for once initialized: ", kind, info);
    pendingUserActions.push({ kind, info });
  } else {
    dispatchPicoUserAction(kind, info);
  }
};

document.addEventListener("turbo:load", ()=> {
  if (pico) {
    addUserInfo();
  }

  document.querySelectorAll(".pico-user-action-event").forEach(element => {
    const payload = JSON.parse(element.textContent) || {};
    window.trackPicoUserAction(payload.kind, payload.info);
  });
});

let webLockResolvers = {};

// A safe implementation of the Web Locks API that will only run if the Web Locks API is supported.
// The resolver of each lock is stored in webLockResolvers object, to release the locks on page unload.
function resolvingWebLockApiRunner(logger) {
  return async (lockId, callback) => {
    if (!navigator.locks || isMobile || isTablet) {
      logger?.debug('Web Locks API not supported');
      await callback();
      return;
    }

    logger?.debug(`Waiting for lock ${lockId}...`);
    await navigator.locks.request(lockId, async () => {
      logger?.debug(`Lock ${lockId} acquired`);
      await callback();
      return new Promise((resolve) => {
        webLockResolvers[lockId] = resolve;
      });
    });
  };
};

function onUnload() {
  for (const lockId in webLockResolvers) {
    webLockResolvers[lockId]();
  }
}

window.addEventListener("beforeunload", onUnload);
window.addEventListener("turbo:before-visit", onUnload);

async function initializePico() {
  if (disableClient) {
    return;
  }

  const lockRunner = resolvingWebLockApiRunner();
  const persistenceManager = await indexedDBPersistence();
  const sessionManagerInstance = await sessionManager({
    persistenceManager,
    runInLock: lockRunner,
    uuidFactory: uuidv4,
    onNewSession: (_, __) => {},
    sessionIdGenerator: syncSessionStorageID('pico_ssid', uuidv4),
  });

  const result = await createPico({
    bspId: bspId,
    environment: { 
      type: 'custom',
      url: environment === 'production'
                        ? 'https://bsp-proxy.loomly.com/events/v4/web-events'
                        : 'https://bsp-proxy.' + environment + '.loomly.com/events/v4/web-events',
     },
    lockRunner,
    persistenceManager,
    userIdGenerators: {
      localStorageId: localStorageID('pico_lsid', uuidv4),
      userId: () => extractUserId(),
    },
    logger: {
      trace: () => {},
      info: () => {},
      debug: () => {},
      warn: console.warn,
      error: console.error,
      fatal: console.error,
    },
    sessionManager: sessionManagerInstance,
    uuidFactory: uuidv4,
  });

  if (result?.result === 'success') {
    pico = result.pico;

    try {
      await addUserInfo();
      dispatchPendingPicoUserActions();
    } catch (e) {}

    debug && console.log('Pico initialized successfully');
  } else {
    console.error('Pico initialization failed:', result?.error);
  }
};

function extractAttributionInfo() {
  // List of the parameters for Pico landing_page_info.
  // All the parameters not in this list will not be sent to Pico as landing_page_info.
  // Please keep this list in alphabetical order to easily spot duplicated, unwanted parameters.
  let queryParamsAllowList = [
    'adgroup',
    'campaign',
    'creative',
    'fbc',
    'fbclid',
    'fbp',
    'gbraid',
    'gclid',
    'google_network',
    'google_placement',
    'match_type',
    'msclkid',
    't_agid',
    't_agname',
    't_cid',
    't_cname',
    't_crid',
    't_crname',
    't_device',
    't_gcid',
    't_match_type',
    't_network',
    't_s',
    't_validation',
    'ttclid',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
    'v',
    'wbraid',
  ];

  let landingSearchParams = new URLSearchParams(window.location.search);
  let initialParams = Object.fromEntries(landingSearchParams);

  let entries = queryParamsAllowList
    .filter(key => typeof initialParams[key] !== 'undefined')
    .map(key => [key, initialParams[key]]);
  return Object.fromEntries(entries);
}

async function addUserInfo() {
  const deviceType = isMobile
    ? 'mobile'
    : isTablet
    ? 'tablet'
    : isDesktop
      ? 'desktop'
      : 'unknown';
  const language = navigator.language;
  const locale = navigator.languages ? navigator.languages[0] : navigator.language;
  const userInfo = {
    device: {
      device_type: deviceType,
      language,
      browser: browserName,
      os: osName,
      os_version: osVersion,
    },
    locale,
  };

  const experiments = extractUserData()?.pico_experiments;

  if (experiments) {
    userInfo.experiment = experiments;
  }

  const attributionInfo = extractAttributionInfo();

  if (attributionInfo && Object.keys(attributionInfo).length > 0) {
    userInfo.landing_page_info = attributionInfo;
  }

  await pico.updateUserInfo(userInfo);
}

function dispatchPendingPicoUserActions() {
  if (pendingUserActions.length > 0) {
    debug && console.log("Dispatching pending Pico User Actions (now that we've initialized): ", pendingUserActions);

    pendingUserActions.forEach(({ kind, info }) => {
      dispatchPicoUserAction(kind, info);
    });

    pendingUserActions = [];
  }
}
